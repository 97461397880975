import {
  ENDPOINTS,
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT_TABLE,
  Status
} from '@constants';
import { IAdvanceSearch, BaseResponse, DataListResponse } from '@dto';
import { UseDataTableProps, UseDataTableResult } from '@hooks-dto';
import APIManager from '@services';
import { isEmpty, omit } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import trans from 'translation';
import { useCancelablePromise, useDidUpdate } from 'uikit-common';

import {
  cacheColumnOrder,
  cacheViewColumns,
  getColumnOrderFromCache,
  normalizeFinalColumns,
  reorderColumnsBasedOnCache
} from './helpers';

const useDataTable = <T = any>({
  name,
  tableName,
  columnsFunc,
  mappedFields,
  otherParams,
  apiURL,
  otherOptions,
  showFilter = true,
  showDownload = true,
  showTimeRange = false,
  isInitFetch = true,
  excludedAdvanceSearch = []
}: UseDataTableProps): UseDataTableResult<T> => {
  const [data, setData] = useState<DataListResponse<T>>({
    data: [],
    page: PAGE_DEFAULT,
    size: PAGE_SIZE_DEFAULT_TABLE,
    total: 0
  });

  const columns = useMemo(() => {
    return columnsFunc(data?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsFunc, JSON.stringify(data?.data)]);

  const [orderedColumns, setOrderedColumns] = useState<any[]>(
    reorderColumnsBasedOnCache(name, columns)
  );

  const mounted = useRef(false);

  useDidUpdate(() => {
    setOrderedColumns(reorderColumnsBasedOnCache(name, columns));
  }, [columns, name]);

  const [keyword, setKeyword] = useState('');
  const [searchField, setSearchField] = useState('keyword');
  const [page, setPage] = useState(PAGE_DEFAULT);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT_TABLE);
  const [sortDirection, setSortDirection] = useState('');
  const [sortField, setSortField] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [advanceSearch, setAdvanceSearch] = useState<IAdvanceSearch[]>([]);
  const [searchConditions, setSearchConditions] = useState<any[]>([]);
  const [status, setStatus] = useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<number | undefined>(
    showTimeRange ? 30 : undefined
  );

  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  const { promise, handleNewPromise } = useCancelablePromise();

  const mParams = useMemo(() => {
    const _mParams: any = {
      page,
      size: pageSize,
      ...otherParams
    };

    if (sortField && sortDirection) {
      const _sortField = mappedFields?.[sortField] || sortField;
      _mParams.sortBy = `${_sortField}:${sortDirection}`;
    }

    if (!isEmpty(keyword)) {
      const _searchField = mappedFields?.[searchField] || searchField;
      _mParams[_searchField] = keyword;
    }

    if (status) {
      _mParams.isUnclosed = true;
    }

    if (timeRange) {
      _mParams.dayRange = timeRange;
    }

    if (!isEmpty(searchConditions)) {
      _mParams.searchCondition = JSON.stringify(searchConditions);
    }

    return _mParams;
  }, [
    keyword,
    mappedFields,
    otherParams,
    page,
    pageSize,
    searchConditions,
    searchField,
    sortDirection,
    sortField,
    status,
    timeRange
  ]);

  const initializeFetch = useCallback(async () => {
    if (apiURL) {
      let _apiURL = apiURL;
      if (!isEmpty(mParams)) {
        _apiURL = `${apiURL}?${qs.stringify(mParams)}`;
      }

      setIsLoading(true);
      if (showFilter) {
        handleNewPromise(
          Promise.all([
            APIManager.request({
              url: _apiURL
            }),
            APIManager.request({
              url: ENDPOINTS.advanceSearch(),
              body: {
                tableName,
                status: Status.Active
              }
            })
          ])
        );
        const [dRes, fRes]: [
          BaseResponse<any>,
          BaseResponse<IAdvanceSearch[]>
        ] = await promise.current;
        if (!dRes?.error) {
          setSelectedIndexes([]);

          if (dRes.data?.data) {
            setData(dRes.data);
          } else {
            setData({ data: dRes.data });
          }

          if (!fRes?.error && fRes.data) {
            setAdvanceSearch(
              fRes.data.reduce<IAdvanceSearch[]>((acc, cur) => {
                if (!excludedAdvanceSearch.includes(cur.columnName)) {
                  acc.push({
                    ...cur,
                    label:
                      columns.find(c => c.mappedName === cur.columnName)
                        ?.label ?? cur.columnName
                  });
                }

                return acc;
              }, [])
              // fRes.data.map(i => ({
              //   ...i,
              //   label:
              //     columns.find(c => c.mappedName === i.columnName)?.label ??
              //     i.columnName
              // }))
            );
          }
        }
      } else {
        handleNewPromise(
          APIManager.request({
            url: _apiURL
          })
        );
        const res: BaseResponse<any> = await promise.current;

        if (res?.data) {
          setSelectedIndexes([]);

          if (res.data?.data) {
            setData(res?.data);
          } else {
            setData({
              data: res?.data
            });
          }
        }
      }

      setIsLoading(false);
    } else if (apiURL) {
      let _apiURL = apiURL;
      if (!isEmpty(mParams)) {
        _apiURL = `${apiURL}?${qs.stringify(mParams)}`;
      }

      setIsLoading(true);
      if (showFilter) {
        handleNewPromise(
          Promise.all([
            APIManager.request({
              url: _apiURL
            }),
            APIManager.request({
              url: ENDPOINTS.advanceSearch(),
              body: {
                tableName,
                status: Status.Active
              }
            })
          ])
        );
        const [dRes, fRes]: [
          BaseResponse<any>,
          BaseResponse<IAdvanceSearch[]>
        ] = await promise.current;
        if (!dRes?.error) {
          setSelectedIndexes([]);

          if (dRes.data?.data) {
            setData(dRes.data);
          } else {
            setData({ data: dRes.data });
          }

          if (!fRes?.error && fRes.data) {
            setAdvanceSearch(
              fRes.data.reduce<IAdvanceSearch[]>((acc, cur) => {
                if (!excludedAdvanceSearch.includes(cur.columnName)) {
                  acc.push({
                    ...cur,
                    label:
                      columns.find(c => c.mappedName === cur.columnName)
                        ?.label ?? cur.columnName
                  });
                }

                return acc;
              }, [])
              // fRes.data.map(i => ({
              //   ...i,
              //   label:
              //     columns.find(c => c.mappedName === i.columnName)?.label ??
              //     i.columnName
              // }))
            );
          }
        }
      } else {
        handleNewPromise(
          APIManager.request({
            url: _apiURL
          })
        );
        const res: BaseResponse<any> = await promise.current;
        if (res?.data) {
          setSelectedIndexes([]);

          if (res.data?.data) {
            setData(res?.data);
          } else {
            setData({
              data: res?.data
            });
          }
        }
      }

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiURL, mParams, showFilter, promise, handleNewPromise, tableName]);

  const initializeFetchFilter = useCallback(async () => {
    handleNewPromise(
      APIManager.request({
        url: ENDPOINTS.advanceSearch(),
        body: {
          tableName,
          status: Status.Active
        }
      })
    );
    const res: BaseResponse<IAdvanceSearch[]> = await promise.current;
    if (!res.error && res.data) {
      setAdvanceSearch(
        res.data.reduce<IAdvanceSearch[]>((acc, cur) => {
          if (!excludedAdvanceSearch.includes(cur.columnName)) {
            acc.push({
              ...cur,
              label:
                columns.find(c => c.mappedName === cur.columnName)?.label ??
                cur.columnName
            });
          }

          return acc;
        }, [])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNewPromise, tableName, promise]);

  useEffect(() => {
    if (!mounted.current && !isInitFetch) {
      mounted.current = true;
      if (showFilter) {
        initializeFetchFilter();
      }
    } else {
      initializeFetch();
    }
  }, [
    initializeFetch,
    initializeFetchFilter,
    isInitFetch,
    promise,
    showFilter
  ]);

  const options: MUIDataTableOptions = useMemo(() => {
    return {
      serverSide: true,
      page,
      rowsPerPage: pageSize,
      count: data?.total ?? 0,
      print: false,
      download: false,
      filter: false,
      sort: true,
      responsive: 'standard',
      search: false,
      selectableRows: 'none',
      selectToolbarPlacement: 'none',
      elevation: 0,
      rowsPerPageOptions: [15, 50, 100],
      rowsSelected: selectedIndexes,
      onChangePage: setPage,
      onChangeRowsPerPage: setPageSize,
      draggableColumns: {
        enabled: true
      },
      columnOrder: getColumnOrderFromCache(name, columns.length),
      enableNestedDataAccess: '.', // allows nested data separated by "."
      // customToolbar: renderToolbar,
      textLabels: {
        body: {
          noMatch: isLoading ? trans('loading_table') : trans('empty_table')
        }
      },
      onColumnSortChange: (changedColumn: any, direction: any) => {
        if (changedColumn && direction) {
          setSortField(changedColumn);
          setSortDirection(direction.toUpperCase());
        }
      },
      onColumnOrderChange: (newColumnOrderInt: any) => {
        cacheColumnOrder(name, newColumnOrderInt);
      },
      onViewColumnsChange: (changedColumn: any, action: any) => {
        cacheViewColumns(name, changedColumn, action);
        setOrderedColumns(reorderColumnsBasedOnCache(name, columns));
      },
      onRowSelectionChange: (_: any, __: any, rows: any) => {
        setSelectedIndexes(rows as number[]);
      },
      ...(otherOptions ?? {})
    };
  }, [
    page,
    pageSize,
    data?.total,
    selectedIndexes,
    name,
    columns,
    isLoading,
    otherOptions
  ]);

  const onDownload = useCallback(
    async (dParams: any): Promise<{ data: any }> => {
      if (showDownload && apiURL) {
        const _mParams = {
          ...omit(mParams, ['page', 'size', 'sortBy']),
          ...dParams
        };

        const res = await APIManager.request({
          url: `${apiURL}/export-excel`,
          body: _mParams,
          showToast: false
        });
        return Promise.resolve({ data: res.data.payload });
      }

      return Promise.resolve({ data: undefined });
    },
    [showDownload, apiURL, mParams]
  );

  const mColumns = useMemo(() => {
    return normalizeFinalColumns(orderedColumns, mappedFields);
  }, [mappedFields, orderedColumns]);

  return {
    name,
    columns: mColumns,
    data,
    options,
    advanceSearch: advanceSearch.map(i => ({
      ...i,
      label:
        mColumns.find(c => c.mappedName === i.columnName)?.label ?? i.columnName
    })),
    page,
    pageSize,
    sortDirection,
    sortField,
    keyword,
    searchField,
    isLoading,
    searchConditions,
    selectedIndexes,
    selectedData: selectedIndexes.map(i => data?.data?.[i]),
    status,
    timeRange,
    showFilter,
    showDownload,
    showTimeRange,

    init: initializeFetch,
    setKeyword,
    setSearchField,
    setSearchConditions,
    setStatus,
    setTimeRange,
    setSelectedIndexes,
    setPage,
    onDownload
  };
};

export default useDataTable;
