import { BaseTextFieldProps, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { trans } from '@utils/i18n';
import React, { memo, useMemo } from 'react';

import { Input } from './Input';

interface ISelect extends BaseTextFieldProps {
  data?: any;
  idKey?: string;
  labelKey?: string;
  labelData?: any;
  customRenderData?: (data: any) => JSX.Element;
}

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Select = ({
  data,
  idKey = 'id',
  labelKey = 'name',
  labelData,
  customRenderData,
  ...otherProps
}: ISelect) => {
  const classes = useStyles();

  const renderData = useMemo(() => {
    if (customRenderData) {
      return customRenderData(data);
    } else if (data) {
      if (Array.isArray(data)) {
        return data.map(i => (
          <MenuItem key={i[idKey]} value={i[idKey]}>
            {i[labelKey]}
          </MenuItem>
        ));
      } else {
        return Object.keys(data).map(i => (
          <MenuItem key={i} value={i}>
            {trans(labelData[i])}
          </MenuItem>
        ));
      }
    }

    return null;
  }, [customRenderData, data, idKey, labelData, labelKey]);

  if (otherProps.disabled) {
    const { label, style, value, SelectProps } = otherProps;

    const v = value
      ? data
        ? Array.isArray(data)
          ? data
              .filter(i =>
                SelectProps?.multiple
                  ? value.includes(i[idKey])
                  : i[idKey] === value
              )
              .map(i => i[labelKey])
              .join(', ')
          : SelectProps?.multiple
            ? value.map(i => trans(labelData[i])).join(', ')
            : trans(labelData[value])
        : ''
      : '';

    return (
      <Input
        id={otherProps?.id ?? otherProps?.name}
        name={otherProps?.name}
        label={label || ''}
        style={style ?? {}}
        disabled
        fullWidth={otherProps?.fullWidth ?? true}
        value={v}
      />
    );
  }

  return (
    <FormControl
      className={classes.formControl}
      variant="outlined"
      size="small"
      fullWidth={otherProps?.fullWidth ?? true}
    >
      <Input select {...otherProps}>
        {renderData}
      </Input>
    </FormControl>
  );
};

export default memo(Select);
