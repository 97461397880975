export const TableNames = {
  CLIENT: 'ClientList',
  CLIENT_ADDRESS: 'ClientAddressList',
  CLIENT_CONTACT: 'ClientContactList',
  CLIENT_AR_BANK_ACCOUNT: 'ClientARBankAccountList',
  CLIENT_AP_BANK_ACCOUNT: 'ClientAPBankAccountList',
  CLIENT_AR_CURRENCY_UPLIFT: 'ClientARCurrencyUpliftList',
  CLIENT_FORWARDER_AGENT: 'ClientForwarderAgentList',
  CLIENT_CUSTOMS_AGENT: 'ClientCustomsAgentList',
  CLIENT_ACCOUNTS_RECEIVABLE: 'ClientAccountReceivableList',
  CLIENT_CONSIGNOR_CONSIGNEE: 'ClientConsignorConsigneeList',
  CLIENT_DELIVERY_WAREHOUSE: 'ClientDeliveryWarehouseList',
  CLIENT_PICKUP_WAREHOUSE: 'ClientPickupWarehouseList',
  CLIENT_SALE_PERSON: 'ClientSalePersonList',

  TRANSPORT_JOB: 'TransportJobList',
  TRANSPORT_JOB_INVOICE: 'TransportJobInvoiceList',

  USER: 'UserList',
  STAFF: 'StaffList',
  EQUIPMENT: 'EquipmentList',
  INVOICE: 'InvoiceList',
  COMPANY_TARIFF: 'CompanyTariffList',
  CLIENT_TARIFF: 'ClientTariffList',

  QUOTATION: 'QuotationList',
  QUOTATION_REQUEST: 'QuotationRequestList',

  CLIENT_RATE: 'ClientRateList',
  CLIENT_RATE_AUTO_RATE: 'ClientRateAutoRateList',

  STATEMENT: 'StatementList',
  STATEMENT_PAYMENT: 'StatementPaymentList',
  STATEMENT_RECEIVABLE_INVOICE: 'StatementReceivableInvoiceList',

  FAULTY_REPORT: 'FaultyReportList',

  CONTAINER_NOT_READY: 'ContainerNotReadyList',
  CONTAINER_READY_SCHEDULE: 'ContainerReadyScheduleList',
  CONTAINER_IN_SCHEDULE: 'ContainerInScheduleList',

  COMPANY_PROFILE_ADDRESS: 'CompanyProfileAddressList',
  COMPANY_PROFILE_CONTACT: 'CompanyProfileContactList',
  COMPANY_PROFILE_BANK_ACCOUNT: 'CompanyProfileBankAccountList',

  SCHEDULE_TRIP_CONTAINER_OVERTIME: 'OvertimeScheduleTripContainerList',

  LIBRARY_OPERATION_FREE_TIME: 'LibraryOperationFreeTimeList',

  ROLE: 'RoleList',

  SYSTEM_LOG: 'SystemLogList'
};

export const DBTableNames = {
  [TableNames.CLIENT]: 'client',
  [TableNames.CLIENT_ADDRESS]: 'site',
  [TableNames.CLIENT_CONTACT]: 'person',
  [TableNames.CLIENT_AR_BANK_ACCOUNT]: 'bank_account',
  [TableNames.CLIENT_AP_BANK_ACCOUNT]: 'bank_account',
  [TableNames.CLIENT_AR_CURRENCY_UPLIFT]: 'currency_uplift',
  [TableNames.CLIENT_SALE_PERSON]: 'sale_person',

  [TableNames.TRANSPORT_JOB]: 'transport_job',
  [TableNames.TRANSPORT_JOB_INVOICE]: 'invoice',

  [TableNames.USER]: 'users',
  [TableNames.STAFF]: 'staff',
  [TableNames.EQUIPMENT]: 'equipment',
  [TableNames.INVOICE]: 'invoice',
  [TableNames.COMPANY_TARIFF]: 'company_tariff',
  [TableNames.CLIENT_TARIFF]: 'client_tariff',

  [TableNames.QUOTATION]: 'quotation',
  [TableNames.QUOTATION_REQUEST]: 'quotation_request',

  [TableNames.CLIENT_RATE]: 'client_rate',
  [TableNames.CLIENT_RATE_AUTO_RATE]: 'auto_rate',

  [TableNames.STATEMENT]: 'statements',
  [TableNames.STATEMENT_PAYMENT]: 'payment',
  [TableNames.STATEMENT_RECEIVABLE_INVOICE]: 'invoice',

  [TableNames.FAULTY_REPORT]: 'faulty_report',

  [TableNames.CONTAINER_IN_SCHEDULE]: 'container_in_schedule',
  [TableNames.CONTAINER_READY_SCHEDULE]: 'container_ready_schedule',
  [TableNames.CONTAINER_NOT_READY]: 'container_not_ready',

  [TableNames.COMPANY_PROFILE_ADDRESS]: 'site',
  [TableNames.COMPANY_PROFILE_CONTACT]: 'person',
  [TableNames.COMPANY_PROFILE_BANK_ACCOUNT]: 'bank_account',

  [TableNames.SCHEDULE_TRIP_CONTAINER_OVERTIME]: 'schedule_trip_container',

  [TableNames.LIBRARY_OPERATION_FREE_TIME]: 'free_time',

  [TableNames.ROLE]: 'role',

  [TableNames.SYSTEM_LOG]: 'log'
};

export enum WebTable {
  OTHER = 'OTHER',

  LOG = 'LOG',
  EMAIL_LOG = 'EMAIL_LOG',

  ROLE = 'ROLE',
  ROLE_PERMISSION = 'ROLE_PERMISSION',
  ROLE_SCREEN = 'ROLE_SCREEN',
  DATA_GROUP = 'DATA_GROUP',

  CLIENT = 'CLIENT',
  CLIENT_ADDRESS = 'CLIENT_ADDRESS',
  CLIENT_CONTACT = 'CLIENT_CONTACT',
  CLIENT_BANK_ACCOUNT = 'CLIENT_BANK_ACCOUNT',
  CLIENT_CURRENCY_UPLIFT = 'CLIENT_CURRENCY_UPLIFT',
  CLIENT_SALE_PERSON = 'CLIENT_SALE_PERSON',
  CLIENT_RELATED_FORWARDER_AGENT = 'CLIENT_RELATED_FORWARDER_AGENT',
  CLIENT_RELATED_CUSTOMS_AGENT = 'CLIENT_RELATED_CUSTOMS_AGENT',
  CLIENT_RELATED_ACCOUNTS_RECEIVABLE = 'CLIENT_RELATED_ACCOUNTS_RECEIVABLE',
  CLIENT_RELATED_CONSIGNOR_CONSIGNEE = 'CLIENT_RELATED_CONSIGNOR_CONSIGNEE',
  CLIENT_RELATED_DELIVERY_WAREHOUSE = 'CLIENT_RELATED_DELIVERY_WAREHOUSE',
  CLIENT_RELATED_PICKUP_WAREHOUSE = 'CLIENT_RELATED_PICKUP_WAREHOUSE',
  CLIENT_NOTE = 'CLIENT_NOTE',
  CLIENT_EDOC = 'CLIENT_EDOC',

  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  COMPANY_CONTACT = 'COMPANY_CONTACT',
  COMPANY_BANK_ACCOUNT = 'COMPANY_BANK_ACCOUNT',

  SHIPPING_LINE_POLICY = 'SHIPPING_LINE_POLICY',
  OVERWEIGHT = 'OVERWEIGHT',
  VESSEL = 'VESSEL',
  CONTAINER_SIZE = 'CONTAINER_SIZE',

  CLIENT_TYPE = 'CLIENT_TYPE',
  CLIENT_STAGE = 'CLIENT_STAGE',
  TAX = 'TAX',
  DIVISION = 'DIVISION',
  ADDRESS_CAPABILITY = 'ADDRESS_CAPABILITY',
  STATE = 'STATE',
  SUBURB = 'SUBURB',

  DRIVER_CHECK_ITEM = 'DRIVER_CHECK_ITEM',
  TRUCK_CHECK_ITEM = 'TRUCK_CHECK_ITEM',
  TRAILER_CHECK_ITEM = 'TRAILER_CHECK_ITEM',
  FREE_TIME_SETTING = 'FREE_TIME_SETTING',

  JOB_TITLE = 'JOB_TITLE',

  MAKE = 'MAKE',
  MODEL = 'MODEL',
  SUB_TYPE = 'SUB_TYPE',
  ACTION_ITEM = 'ACTION_ITEM',
  LEVEL_OF_FAULT = 'LEVEL_OF_FAULT',
  REASON = 'REASON',

  CHARGE_CODE = 'CHARGE_CODE',
  CHARGE_CODE_CATEGORY = 'CHARGE_CODE_CATEGORY',
  CHARGE_CODE_TEMPLATE = 'CHARGE_CODE_TEMPLATE',
  ZONE = 'ZONE',
  ZONE_SUBURB = 'ZONE_SUBURB',

  ACCOUNTING_AR_INVOICE = 'ACCOUNTING_AR_INVOICE',
  ACCOUNTING_AR_CLIENTS_SUMMARY = 'ACCOUNTING_AR_CLIENTS_SUMMARY',
  ACCOUNTING_AR_CLIENTS_SUMMARY_PAYMENT = 'ACCOUNTING_AR_CLIENTS_SUMMARY_PAYMENT',
  ACCOUNTING_AR_CLIENTS_SUMMARY_INVOICE = 'ACCOUNTING_AR_CLIENTS_SUMMARY_INVOICE',
  ACCOUNTING_AR_CLIENTS_SUMMARY_EMAIL = 'ACCOUNTING_AR_CLIENTS_SUMMARY_EMAIL',

  ACCOUNTING_AP_INVOICE = 'ACCOUNTING_AP_INVOICE',
  ACCOUNTING_AP_WIP_INVOICE = 'ACCOUNTING_AP_WIP_INVOICE',
  ACCOUNTING_AP_ACCOUNTS = 'ACCOUNTING_AP_ACCOUNTS',
  ACCOUNTING_AP_ACCOUNTS_PAYMENT = 'ACCOUNTING_AP_ACCOUNTS_PAYMENT',
  ACCOUNTING_AP_ACCOUNTS_INVOICE = 'ACCOUNTING_AP_ACCOUNTS_INVOICE',
  ACCOUNTING_AP_ACCOUNTS_EMAIL = 'ACCOUNTING_AP_ACCOUNTS_EMAIL',

  STATEMENT_NOTE = 'STATEMENT_NOTE',
  STATEMENT_EDOC = 'STATEMENT_EDOC',
  STATEMENT_EMAIL = 'STATEMENT_EMAIL',
  STATEMENT_ALERT = 'STATEMENT_ALERT',

  TRANSPORT_JOB = 'TRANSPORT_JOB',
  CONTAINER = 'CONTAINER',
  TRANSPORT_JOB_AR_INVOICE = 'TRANSPORT_JOB_AR_INVOICE',
  TRANSPORT_JOB_AP_INVOICE = 'TRANSPORT_JOB_AP_INVOICE',
  INVOICE_CHARGES = 'INVOICE_CHARGES',
  TRANSPORT_JOB_NOTE = 'TRANSPORT_JOB_NOTE',
  TRANSPORT_JOB_CONSIGN_NOTE = 'TRANSPORT_JOB_CONSIGN_NOTE',
  TRANSPORT_JOB_AR_NOTE = 'TRANSPORT_JOB_AR_NOTE',
  TRANSPORT_JOB_WAREHOUSE_NOTE = 'TRANSPORT_JOB_WAREHOUSE_NOTE',
  TRANSPORT_JOB_EDOC = 'TRANSPORT_JOB_EDOC',
  TRANSPORT_JOB_EMAIL = 'TRANSPORT_JOB_EMAIL',
  TRANSPORT_JOB_ALERT = 'TRANSPORT_JOB_ALERT',

  ALLOCATION = 'ALLOCATION',
  ALLOCATION_EDOC = 'ALLOCATION_EDOC',
  ALLOCATION_EMAIL = 'ALLOCATION_EMAIL',
  ALLOCATION_ALERT = 'ALLOCATION_ALERT',

  REPORT = 'REPORT',
  REPORT_DRIVER = 'REPORT_DRIVER',
  REPORT_OTHERS = 'REPORT_OTHERS',

  SCHEDULE = 'SCHEDULE',
  SCHEDULE_TRIP_TMP = 'SCHEDULE_TRIP_TMP',

  SCHEDULE_DRIVER = 'SCHEDULE_DRIVER',
  SCHEDULE_TRAILER = 'SCHEDULE_TRAILER',
  SCHEDULE_NOT_READY_TRIP = 'SCHEDULE_NOT_READY_TRIP',
  SCHEDULE_NOT_READY_TRIP_WD = 'SCHEDULE_NOT_READY_TRIP_WD',

  SCHEDULE_TRIP_LIST_ALL = 'SCHEDULE_TRIP_LIST_ALL',
  SCHEDULE_TRIP_LIST_IMPORT = 'SCHEDULE_TRIP_LIST_IMPORT',
  SCHEDULE_TRIP_LIST_EXPORT = 'SCHEDULE_TRIP_LIST_EXPORT',
  SCHEDULE_TRIP_LIST_MISC = 'SCHEDULE_TRIP_LIST_MISC',
  SCHEDULE_TRIP_LIST_RAIL_INBOUND = 'SCHEDULE_TRIP_LIST_RAIL_INBOUND',
  SCHEDULE_TRIP_LIST_RAIL_OUTBOUND = 'SCHEDULE_TRIP_LIST_RAIL_OUTBOUND',

  SCHEDULE_NOTE = 'SCHEDULE_NOTE',
  SCHEDULE_EDOC = 'SCHEDULE_EDOC',
  SCHEDULE_EMAIL = 'SCHEDULE_EMAIL',
  SCHEDULE_ALERT = 'SCHEDULE_ALERT',

  STAFF = 'STAFF',
  STAFF_NOTE = 'STAFF_NOTE',
  STAFF_EDOC = 'STAFF_EDOC',
  STAFF_ALERT = 'STAFF_ALERT',

  EQUIPMENT = 'EQUIPMENT',
  EQUIPMENT_FAULTY_REPORT = 'EQUIPMENT_FAULTY_REPORT',
  EQUIPMENT_MAINTENANCE = 'EQUIPMENT_MAINTENANCE',
  EQUIPMENT_NOTE = 'EQUIPMENT_NOTE',
  EQUIPMENT_EDOC = 'EQUIPMENT_EDOC',
  EQUIPMENT_ALERT = 'EQUIPMENT_ALERT',

  FAULTY_REPORT = 'FAULTY_REPORT',
  MAINTENANCE = 'MAINTENANCE',

  USER = 'USER',
  USER_NOTE = 'USER_NOTE',
  USER_EDOC = 'USER_EDOC',

  TARIFF_RATE_ZONE_SUBURB = 'TARIFF_RATE_ZONE_SUBURB',

  CLIENT_RATE = 'CLIENT_RATE',
  CLIENT_RATE_AUTO_RATE = 'CLIENT_RATE_AUTO_RATE',
  CLIENT_RATE_NOTE = 'CLIENT_RATE_NOTE',
  CLIENT_RATE_EDOC = 'CLIENT_RATE_EDOC',
  CLIENT_RATE_EMAIL = 'CLIENT_RATE_EMAIL',
  CLIENT_RATE_ALERT = 'CLIENT_RATE_ALERT',

  CLIENT_TARIFF = 'CLIENT_TARIFF',
  CLIENT_TARIFF_NOTE = 'CLIENT_TARIFF_NOTE',
  CLIENT_TARIFF_EDOC = 'CLIENT_TARIFF_EDOC',
  CLIENT_TARIFF_EMAIL = 'CLIENT_TARIFF_EMAIL',
  CLIENT_TARIFF_ALERT = 'CLIENT_TARIFF_ALERT',

  COMPANY_TARIFF = 'COMPANY_TARIFF',
  COMPANY_TARIFF_NOTE = 'COMPANY_TARIFF_NOTE',
  COMPANY_TARIFF_EDOC = 'COMPANY_TARIFF_EDOC',
  COMPANY_TARIFF_EMAIL = 'COMPANY_TARIFF_EMAIL',

  QUOTATION = 'QUOTATION',
  QUOTATION_REQUEST = 'QUOTATION_REQUEST',
  QUOTATION_NOTE = 'QUOTATION_NOTE',
  QUOTATION_EDOC = 'QUOTATION_EDOC',
  QUOTATION_EMAIL = 'QUOTATION_EMAIL',
  QUOTATION_ALERT = 'QUOTATION_ALERT',

  ISSUE = 'ISSUE',
  FAQ = 'FAQ',
  USER_GUIDE = 'USER_GUIDE',

  LOCAL_PAYMENT_INVOICE = 'LOCAL_PAYMENT_INVOICE'
}

export enum TableName {
  CLIENT = 'client',

  TRANSPORT_JOB = 'transport_job',
  INVOICE = 'invoice',

  VESSEL = 'vessel',
  CONTAINER_SIZE = 'container_size',

  SCHEDULE_TRIP_TMP = 'schedule_trip_tmp',
  SCHEDULE_TRIP = 'schedule_trip',
  NOT_READY_TRIP = 'v_trip_not_ready_schedule',

  STATEMENT = 'statements',
  PAYMENT = 'payment',

  STAFF = 'staff',

  USER = 'users',

  EQUIPMENT = 'equipment',
  FAULTY_REPORT = 'faulty_report',
  MAINTENANCE = 'maintenance',

  NOTE = 'note',
  EDOC = 'edoc',

  COMPANY_TARIFF = 'company_tariff',

  CLIENT_TARIFF = 'client_tariff',

  QUOTATION = 'quotation',
  QUOTATION_REQUEST = 'quotation_request',
  CLIENT_RATE = 'client_rate',
  AUTO_RATE = 'auto_rate',

  ISSUE = 'issue'
}
