import {
  ActionType,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  SignatureSignType,
  Status,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';
import { UIUtils } from '@utils';
import { toast } from 'react-toastify';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useUploadFile,
  useQueryEnhancer
} from '../core';

export const useFetchEquipmentFaultyReport = (
  id?: number,
  equipmentId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.equipmentFaultyReport,
      equipmentId ? parseInt(equipmentId as string) : null,
      id
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/faulty-report/:faultyReportId', {
          id: equipmentId,
          faultyReportId: id
        })
      }).then(async response => {
        const [repairRes, parentRes] = await Promise.all([
          APIManager.request({
            url: ENDPOINTS.faultyReport(':id/repairer-report', {
              id: response?.data?.id
            }),
            body: {
              size: 1
            }
          }),
          APIManager.request({
            url: ENDPOINTS.equipment(':id/parent-equipment', {
              id: response?.data?.equipmentId
            }),
            body: {
              status: 'ACTIVE'
            }
          })
        ]);

        if (repairRes?.data?.data?.length > 0) {
          Object.assign(response?.data, {
            repairerReport: {
              ...repairRes?.data?.data?.[0]
            }
          });
        }

        if (parentRes?.data?.length > 0) {
          Object.assign(response?.data, {
            parentEquipment: {
              ...parentRes?.data?.[0]
            }
          });
        }
        return response?.data;
      });
      return res;
    },
    enabled: !!id && !!equipmentId
  });
};

export const useFetchParentEquipment = (subEquipmentId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.equipmentParentEquipment,
      subEquipmentId ? parseInt(subEquipmentId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/parent-equipment', {
          id: subEquipmentId
        })
      });
      return res.data?.[0];
    },
    enabled: !!subEquipmentId
  });
};

export const useFetchEquipmentLatestFaultyReport = (
  equipmentId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.equipmentLatestFaultyReport,
      equipmentId ? parseInt(equipmentId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/faulty-report', {
          id: equipmentId
        }),
        body: {
          sortBy: 'createdAt:DESC',
          isCancelled: false,
          size: 1
        }
      });

      return res.data?.data?.[0];
    },
    enabled: !!equipmentId
  });
};

export const useFetchLatestFaultyReport = () => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.faultyReportLatestReport],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.faultyReport(),
        body: {
          sortBy: 'createdAt:DESC',
          isCancelled: false,
          size: 1
        }
      });

      return res.data?.data?.[0];
    }
  });
};

export const useFetchFaultyReportActionList = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.faultyReportActionList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.action(),
        body: {
          status: Status.Active,
          type: ActionType.Faulty,
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useFetchFaultyReportLevelOfFaultList = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.faultyReportLevelOfFaultList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.levelOfFault(),
        body: {
          status: Status.Active,
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useFetchFaultyReportReasonList = () => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.faultyReportReasonList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.reason(),
        body: {
          status: Status.Active,
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC'
        }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useCUDEquipmentFaultyReport = (equipmentId?: string | number) => {
  return useCUDMutationEnhancer({
    endPoint: ENDPOINTS.equipment(':equipmentId/faulty-report', {
      equipmentId
    }),
    webTable: WebTable.EQUIPMENT_FAULTY_REPORT,
    dependentWebTable: [WebTable.FAULTY_REPORT],
    noDismiss: true,
    isPage: true,
    mutationKeys: [[QUERY_KEYS.equipmentFaultyReport]],
    deleteWithoutRefetch: true
  });
};

export const useCUDFaultyReportRepairerReport = (
  faultyReportId?: string | number
) => {
  return useCUDMutationEnhancer({
    endPoint: ENDPOINTS.faultyReport(':id/repairer-report', {
      id: faultyReportId
    }),
    webTable: WebTable.EQUIPMENT_FAULTY_REPORT,
    dependentWebTable: [
      WebTable.FAULTY_REPORT,
      WebTable.EQUIPMENT_FAULTY_REPORT
    ],
    noDismiss: false,
    isPage: true,
    mutationKeys: [[QUERY_KEYS.equipmentFaultyReport]],
    deleteWithoutRefetch: true
  });
};

export const useFetchFaultyReportRepairerReport = (
  faultyReportId: string | number | undefined
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.faultyReportRepairerReportSingle],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.faultyReport(':id/repairer-report', {
          id: faultyReportId
        }),
        body: {
          size: 1
        }
      });
      return res.data?.data?.[0];
    }
  });
};

export const useSignFaultyReport = (signType: SignatureSignType) => {
  const { mutateAsync: mutateFile } = useUploadFile();

  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.equipmentFaultyReport]],
    mutationFn: async data => {
      const { blob, equipmentId, id, ...rest } = data;
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob);
        const fRes = await mutateFile(formData);

        if (fRes?.id) {
          rest[`${signType.toLowerCase()}SignatureId`] = fRes.id;
        }
      }

      const res = await APIManager.request({
        url: ENDPOINTS.equipment(
          ':id/faulty-report/:faultyReportId/:signType',
          {
            id: equipmentId,
            faultyReportId: id,
            signType: signType.toLowerCase()
          }
        ),
        method: 'PUT',
        body: rest
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        tableRef?.[WebTable.EQUIPMENT_FAULTY_REPORT]?.init();
      }
    }
  });
};

export const useCloseFaultyReport = () => {
  return useMutationEnhancer<any, any>({
    mutationKey: [QUERY_KEYS.faultyReportClose],
    mutationFn: async data => {
      const { equipmentId, id, staffId } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/faulty-report/:faultyReportId/close', {
          id: equipmentId,
          faultyReportId: id
        }),
        method: 'PUT',
        body: {
          closedOffById: staffId
        }
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        tableRef?.[WebTable.EQUIPMENT_FAULTY_REPORT]?.init();
        tableRef?.[WebTable.FAULTY_REPORT]?.init();
      }
    }
  });
};

export const useUncloseFaultyReport = () => {
  return useMutationEnhancer<any, any>({
    mutationKey: [QUERY_KEYS.faultyReportClose],
    mutationFn: async data => {
      const { equipmentId, id } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.equipment(':id/faulty-report/:faultyReportId/unclose', {
          id: equipmentId,
          faultyReportId: id
        }),
        method: 'PUT',
        body: {}
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        tableRef?.[WebTable.EQUIPMENT_FAULTY_REPORT]?.init();
        tableRef?.[WebTable.FAULTY_REPORT]?.init();
        console.log('Unclosed', data);
      }
    }
  });
};

export const useUnApproveFaultyReport = () => {
  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.equipmentFaultyReport]],
    mutationFn: async data => {
      const { equipmentId, id } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.equipment(
          ':id/faulty-report/:faultyReportId/unapprove',
          {
            id: equipmentId,
            faultyReportId: id
          }
        ),
        method: 'PUT'
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        toast.success('The Faulty Report is unapproved');
        tableRef?.[WebTable.FAULTY_REPORT]?.init?.();
        tableRef?.[WebTable.EQUIPMENT_FAULTY_REPORT]?.init?.();
      }
    }
  });
};
