import { YesOrNo } from '@constants';
import { Prototype } from '@core';
import { ICellRendererParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { CSSProperties } from 'react';
import { KChip, KChipProps, KColors, KLabel, KTextProps } from 'uikit';

interface ITableChip extends Omit<KChipProps, 'label'> {
  label?: string;
}

export default class AgUtils {
  static options = {
    text: (css?: KTextProps) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        if (v) {
          return <KLabel.Text {...css}>{v}</KLabel.Text>;
        }

        return null;
      }
    }),

    chip: (data?: {
      css?: CSSProperties;
      uppercase?: boolean;
      width?: number;
      props?: ITableChip;
    }) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        if (v) {
          return (
            <KChip
              background={v?.backgroundColor || '#DEFFFD'}
              brC={v?.border || KColors.primary.normal}
              label={v?.name ?? v}
              textTransform={data?.uppercase ? 'uppercase' : 'capitalize'}
              color={v?.color || KColors.primary.normal}
              width={data?.width}
              {...data?.props}
            />
          );
        }

        return null;
      }
    }),
    chips: (data: {
      uppercase?: boolean;
      width?: number;
      mapLabelData: {
        [key: string]: ITableChip;
      };
      notFoundKey?: string;
    }) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        const { uppercase = true, notFoundKey, mapLabelData } = data;
        let item = v;
        if (v) {
          if (typeof v === 'boolean') {
            item = {
              name: v,
              ...(mapLabelData?.[`${v}`] ??
                mapLabelData?.[`${v}`.toLowerCase()])
            };
          } else {
            item = {
              name: v,
              ...(mapLabelData?.[v] ?? mapLabelData?.[v.toLowerCase()])
            };
          }
        } else if (notFoundKey) {
          item = {
            name: notFoundKey,
            ...(mapLabelData?.[`${notFoundKey}`] ??
              mapLabelData?.[`${notFoundKey}`.toLowerCase()])
          };
        }

        if (item) {
          const { name, label, ...rest } = item;
          return (
            <KChip
              background={item?.backgroundColor || '#DEFFFD'}
              brC={KColors.primary.normal}
              label={label ?? name ?? ''}
              textTransform={uppercase ? 'uppercase' : 'capitalize'}
              color={KColors.primary.normal}
              width={data?.width}
              {...rest}
            />
          );
        }

        return null;
      }
    }),

    currency: (defaultValue: any = '', options?: any) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        return !Prototype.core.isNullOrUndefined(v) && isNumber(v)
          ? Prototype.number.formatCurrency(v, {
              withAbs: v < 0,
              withSpace: true,
              ...options
            })
          : !Prototype.core.isNullOrUndefined(v) &&
              isNumber(parseFloat(v as string))
            ? Prototype.number.formatCurrency(v, {
                withAbs: parseFloat(v as string) < 0,
                withSpace: true,
                ...options
              })
            : defaultValue;
      }
    }),

    boolean: (data: [any, any] = [YesOrNo.Yes, YesOrNo.No]) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        return v ? data[0] : data[1];
      }
    }),

    withEllipsis: {
      cellRenderer: ({ value: v }: ICellRendererParams<any>) =>
        v ? <KLabel.Text numberOfLines={4}>{v}</KLabel.Text> : null
    },
    withNA: {
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        return v || 'N/A';
      }
    }
  };
}
