import { ENDPOINTS, tableRef } from '@constants';
import {
  UseCUDMutationOptions,
  UseGenericMutationOptions,
  UseMainCUDMutationOptions
} from '@hooks-dto';
import APIManager from '@services';
import { useQueryClient } from '@tanstack/react-query';
import { UIUtils } from '@utils';
import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import trans from 'translation';
import { KColors } from 'uikit';

import { useMutationEnhancer } from './useRequestProcessor';

export const useGenericMutationEnhancer = <T = unknown>(
  options: UseGenericMutationOptions
) => {
  const {
    endPoint,
    method,
    webTable,
    dependentWebTable,
    alwaysReturnData,
    returnedData,
    isPage,
    onSuccess,
    noDismiss,
    ...rest
  } = options;

  return useMutationEnhancer<boolean | any | undefined, T>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: generatePath(endPoint, data as any),
        method,
        showToast: (data as any)?.showToast ?? true,
        body: data
      });

      return alwaysReturnData ||
        (returnedData && method === 'POST') ||
        (isPage && method !== 'DELETE')
        ? res?.data
        : res?.success;
    },
    onSuccess: (data, variables) => {
      if (data) {
        if (webTable) {
          tableRef?.[webTable]?.init();
        }
        dependentWebTable?.forEach(i => {
          tableRef?.[i]?.init();
        });
        if (!noDismiss) {
          UIUtils.popup.dismiss();
        }
        onSuccess?.({ data, variables, options });
      }
    },
    ...rest
  });
};

export const useCUDMutationEnhancer = <T = unknown>(
  options: UseCUDMutationOptions
) => {
  const createMutation = useGenericMutationEnhancer<T>({
    ...options,
    method: 'POST'
  });

  const updateMutation = useGenericMutationEnhancer<T>({
    ...options,
    method: 'PUT',
    endPoint: options.putWithoutId
      ? options.endPoint
      : `${options.endPoint}/:id`
  });

  const deleteMutation = useGenericMutationEnhancer<any>({
    ...options,
    method: 'DELETE',
    endPoint: `${options.endPoint}/:id`,
    mutationKeys: options.deleteWithoutRefetch ? [] : options.mutationKeys
  });

  const defaultMutation = useGenericMutationEnhancer<any>({
    ...options,
    method: 'PUT',
    endPoint: `${options.endPoint}/:id/default`
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    defaultMutation,
    modifyLoading: createMutation.isLoading || updateMutation.isLoading
  };
};

export const useAlertMutationEnhancer = (options: {
  // eslint-disable-next-line prettier/prettier
  mutation: ReturnType<typeof useGenericMutationEnhancer<any>>;
  message?: string;
  okLabel?: string;
}): ReturnType<typeof useGenericMutationEnhancer<any>> & {
  onAlert: (
    mParams: any,
    onSuccess?: () => void,
    onSettled?: () => void
  ) => void;
  deleteLoading: boolean;
} => {
  const { mutation, message, okLabel } = options;
  const { mutate, ...rest } = mutation;

  const onAlert = useCallback(
    (mParams: any, onSuccess?: () => void, onSettled?: () => void) => {
      UIUtils.alert.open({
        message: mParams?.message || message || trans('message_delete'),
        buttons: [
          {
            title: okLabel || trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(mParams, { onSuccess, onSettled })
          },
          {
            title: trans('cancel'),
            onPress: onSettled ? onSettled : undefined
          }
        ]
      });
    },
    [message, mutate, okLabel]
  );

  return { onAlert, mutate, deleteLoading: rest.isLoading, ...rest };
};

export const usePageCUDMutationEnhancer = <T = unknown>(
  options: UseMainCUDMutationOptions
) => {
  const {
    queryKey,
    redirectPath,
    redirectKey,
    refetchAfterPut,
    putWithoutSuccess,
    deleteWithoutSuccess,
    ...rest
  } = options;
  const queryClient = useQueryClient();
  const history = useHistory();

  const createMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (
        data &&
        (data.id || (redirectKey && data[redirectKey])) &&
        redirectPath
      ) {
        history.replace(
          `${redirectPath}/${redirectKey ? data[redirectKey] : data.id}`
        );
      }
    },
    isPage: true,
    ...rest,
    method: 'POST'
  });

  const updateMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey && !putWithoutSuccess) {
        if (refetchAfterPut) {
          queryClient.invalidateQueries([queryKey], { type: 'active' });
        } else {
          queryClient.setQueriesData(
            { queryKey: [queryKey], type: 'active' },
            data
          );
        }
      }
    },
    isPage: true,
    ...rest,
    method: 'PUT',
    endPoint: rest.putWithoutId ? rest.endPoint : `${rest.endPoint}/:id`
  });

  const deleteMutation = useGenericMutationEnhancer<number>({
    onSuccess: () => {
      if (!deleteWithoutSuccess) {
        if (redirectPath) {
          history.replace(redirectPath);
        } else if (rest.webTable && !tableRef?.[rest.webTable]) {
          history.go(-1);
        }
      }
    },
    ...rest,
    method: 'DELETE',
    endPoint: `${rest.endPoint}/:id`
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    modifyLoading: createMutation.isLoading || updateMutation.isLoading,
    isLoading:
      createMutation.isLoading ||
      updateMutation.isLoading ||
      deleteMutation.isLoading
  };
};

export const useUploadFile = () => {
  return useMutationEnhancer<{ url: string; id: number }, FormData>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.resource('upload'),
        method: 'POST',
        body: data,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      return res.data;
    }
  });
};

export const useParsePdf = () => {
  return useMutationEnhancer<{ url: string; id: number }, FormData>({
    mutationFn: async data => {
      const templateId = data.get('templateId');
      const res = await APIManager.request({
        url: ENDPOINTS.pdfTemplateService('pdf-parse-json/:templateId', {
          templateId
        }),
        method: 'POST',
        body: data,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      return res.data;
    }
  });
};

export const usePrint = () => {
  const mutation = useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.template('render'),
        method: 'POST',
        body: data,
        configs: {
          responseType: 'blob'
        }
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: 'application/pdf' })
        );
        window.open(url);
      }
    }
  });

  return { ...mutation, isPrinting: mutation.isLoading };
};
