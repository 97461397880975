import { IS_LOCAL_XERO } from '@constants';
import appStorage from '@utils/appStorage';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { generatePath } from 'react-router-dom';

export const TIMEOUT = 10 * 60 * 1000; // 10 minutes

export const QUERY_KEYS = {
  user: 'queries.user',

  advanceSearch: 'queries.advanceSearch',

  dashboardIssuesEquipment: 'queries.dashboardIssuesEquipment',
  dashboardIssuesDriver: 'queries.dashboardIssuesDriver',
  dashboardIssuesClientRate: 'queries.dashboardIssuesClientRate',
  dashboardTransportJobs: 'queries.dashboardTransportJobs',
  dashboardContainers: 'queries.dashboardContainers',
  dashboardPayments: 'queries.dashboardPayments',
  dashboardInvoicesOverdue: 'queries.dashboardInvoicesOverdue',
  dashboardAROverdue: 'queries.dashboardAROverdue',
  dashboardAllocationReports: 'queries.dashboardAllocationReports',
  dashboardAllocationMessages: 'queries.dashboardAllocationMessages',
  dashboardScheduleTripContainer: 'queries.dashboardScheduleTripContainer',

  systemSetting: 'queries.systemSetting',

  notification: 'queries.notification',
  notificationCount: 'queries.notificationCount',

  oneStopNotification: 'queries.oneStopNotification',
  oneStopNotificationCount: 'queries.oneStopNotificationCount',

  dataGroup: 'queries.dataGroup',
  role: 'queries.role',
  permissions: 'queries.permissions',
  screens: 'queries.screens',

  client: 'queries.client',
  clientTypes: 'queries.clientTypes',
  addressCapabilities: 'queries.addressCapabilities',
  clientAccountSetting: 'queries.clientAccountSetting',
  bankAccounts: 'queries.bankAccounts',

  scheduleTripTmpList: 'queries.scheduleTripTmpList',
  scheduleTripContainerTmp: 'queries.scheduleTripContainerTmp',
  scheduleTripTmpNoteList: 'queries.scheduleTripTmpNoteList',
  scheduleTripTmpNoteListSignals: 'queries.scheduleTripTmpNoteListSignals',

  schedule: 'queries.schedule',
  scheduleDriverList: 'queries.scheduleDriverList',
  scheduleCalendar: 'queries.scheduleCalendar',
  scheduleNext: 'queries.scheduleNext',
  schedulePrevious: 'queries.schedulePrevious',
  scheduleContainer: 'queries.scheduleContainer',
  scheduleTrip: 'queries.scheduleTrip',
  scheduleDriverStatistic: 'queries.scheduleDriverStatistic',
  scheduleDriverTripList: 'queries.scheduleDriverTripList',
  scheduleTrailerList: 'queries.scheduleTrailerList',
  scheduleNotReadyTripList: 'queries.scheduleNotReadyTripList',
  scheduleDriverTripListForMap: 'queries.scheduleDriverTripListForMap',
  driverLocationListForMap: 'queries.driverLocationListForMap',
  scheduleContainerNoteList: 'queries.scheduleContainerNoteList',

  checkItemList: 'queries.checkItemList',
  safetyCheck: 'queries.safetyCheck',

  conversation: 'queries.conversation',
  messageList: 'queries.messageList',

  company: 'queries.company',

  statement: 'queries.statement',
  statementLatestPayment: 'queries.statementLatestPayment',
  payment: 'queries.payment',
  paymentList: 'queries.paymentList',
  paymentInvoiceList: 'queries.paymentInvoiceList',

  templateList: 'queries.templateList',
  templateReportList: 'queries.templateReportList',

  containerEdocList: 'queries.containerEdocList',

  staff: 'queries.staff',

  equipmentList: 'queries.equipmentList',
  equipment: 'queries.equipment',
  equipmentFaultyReport: 'queries.equipmentFaultyReport',
  equipmentLatestFaultyReport: 'queries.equipmentLatestFaultyReport',
  equipmentMaintenance: 'queries.equipmentMaintenance',
  equipmentLatestMaintenance: 'queries.equipmentLatestMaintenance',
  equipmentParentEquipment: 'queries.parentEquipment',

  faultyReportLatestReport: 'queries.faultyReportLatestReport',
  faultyReportEquipmentFaultyReportList:
    'queries.faultyReportEquipmentFaultyReportList',
  faultyReportActionList: 'queries.faultyReportActionList',
  faultyReportLevelOfFaultList: 'queries.faultyReportLevelOfFaultList',
  faultyReportReasonList: 'queries.faultyReportReasonList',
  faultyReportClose: 'queries.faultyReportClose',
  faultyReportRepairerReport: 'queries.faultyReportRepairerReport',
  faultyReportRepairerReportSingle: 'queries.faultyReportRepairerReportSingle',
  faultyReportUnApprove: 'queries.faultyReportUnApprove',

  maintenanceActionList: 'queries.maintenanceActionList',

  transportJobList: 'queries.transportJobList',
  transportJob: 'queries.transportJob',

  container: 'queries.container',
  containerList: 'queries.containerList',
  containerOverweight: 'queries.containerOverweight',
  containerRouting: 'queries.containerRouting',

  invoice: 'queries.invoice',
  invoiceList: 'queries.invoiceList',
  taxes: 'queries.taxes',

  report: 'queries.report',
  reportLeaveOfTrip: 'queries.reportLeaveOfTrip',

  quotation: 'queries.quotation',
  quotationRequest: 'queries.quotationRequest',
  quotationRequestList: 'queries.quotationRequestList',

  clientRate: 'queries.clientRate',
  clientRateAutoRate: 'queries.clientRateAutoRate',

  companyTariff: 'queries.companyTariff',

  clientTariff: 'queries.clientTariff',

  hasXero: 'queries.hasXero',
  xeroStatus: 'queries.xeroStatus',
  xeroTenantList: 'queries.xeroTenantList',

  issue: 'queries.issue',
  userGuide: 'queries.userGuide'
};

export const COMMON_HEADERS = {
  responseType: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json;charset=UTF-8',
  'X-Zone-Id': moment.tz.guess(true),
  'X-companyId': '18'
};

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: COMMON_HEADERS,
  timeout: TIMEOUT
});

export const apiInstanceWithoutBase = axios.create({
  baseURL: '',
  headers: COMMON_HEADERS,
  timeout: TIMEOUT
});

export const updateAuthorizationToken = (token?: string) => {
  if (token) {
    appStorage.setAccessToken(token);
    apiInstance.defaults.headers.common.Authorization = 'Bearer ' + token;
  } else {
    apiInstance.defaults.headers.common.Authorization = '';
  }
};

export const resource = {
  resourceURL: process.env.REACT_APP_RESOURCE_URL
};

const generateServiceEndpoint = (
  serviceName: string,
  path: string,
  query?: any
) => {
  const _service = serviceName ? `/${serviceName}` : '';
  const _path = path ? `${_service}/${path}` : `${_service}`;
  if (isEmpty(query) || Object.values(query).some(i => !i)) {
    return _path;
  }
  return generatePath(_path, query);
};

export const ENDPOINTS = {
  advanceSearch: (path: string = '', query?: any) =>
    generateServiceEndpoint('advance-search', path, query),

  common: (path: string = '', query?: any) =>
    process.env.REACT_APP_BASE_API === 'https://api.tech-demo.online'
      ? generateServiceEndpoint('common', path, query)
      : generateServiceEndpoint('', path, query),

  notification: (path: string = '', query?: any) =>
    generateServiceEndpoint('notification-log', path, query),

  systemSetting: (path: string = '', query?: any) =>
    generateServiceEndpoint('system-setting', path, query),

  role: (path: string = '', query?: any) =>
    generateServiceEndpoint('role', path, query),
  permission: (path: string = '', query?: any) =>
    generateServiceEndpoint('permission', path, query),
  screen: (path: string = '', query?: any) =>
    generateServiceEndpoint('screen', path, query),

  resource: (path: string = '', query?: any) =>
    generateServiceEndpoint('resource', path, query),
  dropMode: (path: string = '', query?: any) =>
    generateServiceEndpoint('drop-mode', path, query),
  containerSize: (path: string = '', query?: any) =>
    generateServiceEndpoint('container-size', path, query),
  overweight: (path: string = '', query?: any) =>
    generateServiceEndpoint('overweight', path, query),
  doorType: (path: string = '', query?: any) =>
    generateServiceEndpoint('door-type', path, query),
  progressStatus: (path: string = '', query?: any) =>
    generateServiceEndpoint('progress-status', path, query),
  clientType: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-type', path, query),
  clientStage: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-stage', path, query),
  category: (path: string = '', query?: any) =>
    generateServiceEndpoint('category', path, query),
  tax: (path: string = '', query?: any) =>
    generateServiceEndpoint('tax', path, query),
  addressCapability: (path: string = '', query?: any) =>
    generateServiceEndpoint('site-capability', path, query),
  vessel: (path: string = '', query?: any) =>
    generateServiceEndpoint('vessel', path, query),
  containerLength: (path: string = '', query?: any) =>
    generateServiceEndpoint('container-length', path, query),
  containerType: (path: string = '', query?: any) =>
    generateServiceEndpoint('container-type', path, query),
  jobTitle: (path: string = '', query?: any) =>
    generateServiceEndpoint('job-title', path, query),
  equipmentType: (path: string = '', query?: any) =>
    generateServiceEndpoint('equipment-type', path, query),
  make: (path: string = '', query?: any) =>
    generateServiceEndpoint('make', path, query),
  model: (path: string = '', query?: any) =>
    generateServiceEndpoint('model', path, query),
  checkItem: (path: string = '', query?: any) =>
    generateServiceEndpoint('check-item', path, query),
  dataGroup: (path: string = '', query?: any) =>
    generateServiceEndpoint('data-group', path, query),
  action: (path: string = '', query?: any) =>
    generateServiceEndpoint('action', path, query),
  levelOfFault: (path: string = '', query?: any) =>
    generateServiceEndpoint('level-of-fault', path, query),
  reason: (path: string = '', query?: any) =>
    generateServiceEndpoint('reason', path, query),
  freeTimeSetting: (path: string = '', query?: any) =>
    generateServiceEndpoint('free-time-setting', path, query),

  // METADATA
  country: (path: string = '', query?: any) =>
    generateServiceEndpoint('country', path, query),
  city: (path: string = '', query?: any) =>
    generateServiceEndpoint('city', path, query),
  state: (path: string = '', query?: any) =>
    generateServiceEndpoint('state', path, query),
  suburb: (path: string = '', query?: any) =>
    generateServiceEndpoint('suburb', path, query),
  unloco: (path: string = '', query?: any) =>
    generateServiceEndpoint('unloco', path, query),
  nationality: (path: string = '', query?: any) =>
    generateServiceEndpoint('nationality', path, query),
  division: (path: string = '', query?: any) =>
    generateServiceEndpoint('division', path, query),
  language: (path: string = '', query?: any) =>
    generateServiceEndpoint('language', path, query),
  currency: (path: string = '', query?: any) =>
    generateServiceEndpoint('currency', path, query),
  timezone: (path: string = '', query?: any) =>
    generateServiceEndpoint('timezone', path, query),

  client: (path: string = '', query?: any) =>
    generateServiceEndpoint('client', path, query),

  source: (path: string = '', query?: any) =>
    generateServiceEndpoint('source', path, query),

  note: (path: string = '', query?: any) =>
    generateServiceEndpoint('note', path, query),
  noteType: (path: string = '', query?: any) =>
    generateServiceEndpoint('note-type', path, query),

  edoc: (path: string = '', query?: any) =>
    generateServiceEndpoint('edoc', path, query),
  edocType: (path: string = '', query?: any) =>
    generateServiceEndpoint('edoc-type', path, query),

  log: (path: string = '', query?: any) =>
    generateServiceEndpoint('log', path, query),

  email: (path: string = '', query?: any) =>
    generateServiceEndpoint('email-log', path, query),
  template: (path: string = '', query?: any) =>
    generateServiceEndpoint('template', path, query),
  importTemplate: (path: string = '', query?: any) =>
    generateServiceEndpoint('import-template', path, query),

  pdfTemplate: (path: string = '', query?: any) =>
    generateServiceEndpoint('pdf-template', path, query),
  pdfTemplateService: (path: string = '', query?: any) =>
    generateServiceEndpoint('pdf-template-service/api', path, query),

  alert: (path: string = '', query?: any) =>
    generateServiceEndpoint('notification-schedule', path, query),

  chargeCode: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code', path, query),
  chargeCodeCategory: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code-category', path, query),
  chargeCodeSubCategory: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code-sub-category', path, query),
  chargeCodeUnit: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code-unit', path, query),
  chargeCodeTemplate: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code-template', path, query),
  zone: (path: string = '', query?: any) =>
    generateServiceEndpoint('zone', path, query),
  zoneSuburb: (path: string = '', query?: any) =>
    generateServiceEndpoint('zone-suburb', path, query),

  company: (path: string = '', query?: any) =>
    generateServiceEndpoint('company', path, query),

  equipment: (path: string = '', query?: any) =>
    generateServiceEndpoint('equipment', path, query),
  faultyReport: (path: string = '', query?: any) =>
    generateServiceEndpoint('faulty-report', path, query),
  maintenance: (path: string = '', query?: any) =>
    generateServiceEndpoint('maintenance', path, query),

  staff: (path: string = '', query?: any) =>
    generateServiceEndpoint('staff', path, query),

  user: (path: string = '', query?: any) =>
    generateServiceEndpoint('user', path, query),

  shippingLinePolicy: (path: string = '', query?: any) =>
    generateServiceEndpoint('shipping-line-dehire', path, query),

  statement: (path: string = '', query?: any) =>
    generateServiceEndpoint('statement', path, query),
  payment: (path: string = '', query?: any) =>
    generateServiceEndpoint('payment', path, query),
  paymentDetail: (path: string = '', query?: any) =>
    generateServiceEndpoint('payment-detail', path, query),

  transportJob: (path: string = '', query?: any) =>
    generateServiceEndpoint('transport-job', path, query),
  container: (path: string = '', query?: any) =>
    generateServiceEndpoint('job-container', path, query),
  invoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('transport-job/invoice', path, query),

  schedule: (path: string = '', query?: any) =>
    generateServiceEndpoint('schedule', path, query),
  scheduleTrip: (path: string = '', query?: any) =>
    generateServiceEndpoint('schedule-trip', path, query),
  scheduleTripLocation: (path: string = '', query?: any) =>
    generateServiceEndpoint('schedule-trip-location', path, query),

  scheduleTripTmp: (path: string = '', query?: any) =>
    generateServiceEndpoint('schedule-trip-tmp', path, query),
  scheduleTripContainerTmp: (path: string = '', query?: any) =>
    generateServiceEndpoint('schedule-trip-container-tmp', path, query),

  scheduleDriver: (path: string = '', query?: any) =>
    generateServiceEndpoint('schedule-driver', path, query),
  scheduleTripContainer: (path: string = '', query?: any) =>
    generateServiceEndpoint('schedule-trip-container', path, query),

  safetyCheck: (path: string = '', query?: any) =>
    generateServiceEndpoint('safety-check', path, query),
  conversation: (path: string = '', query?: any) =>
    generateServiceEndpoint('conversation', path, query),

  report: (path: string = '', query?: any) =>
    generateServiceEndpoint('report', path, query),

  quotation: (path: string = '', query?: any) =>
    generateServiceEndpoint('quotation', path, query),

  companyTariff: (path: string = '', query?: any) =>
    generateServiceEndpoint('company-tariff', path, query),
  clientTariff: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-tariff', path, query),
  clientTariffCharge: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-tariff-charge', path, query),
  clientRate: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-rate', path, query),

  xero: (path: string = '', query?: any) =>
    generateServiceEndpoint(IS_LOCAL_XERO ? '' : 'xero-service', path, query),

  reportExcelType: (path: string = '', query?: any) =>
    generateServiceEndpoint('report-excel-type', path, query),

  issue: (path: string = '', query?: any) =>
    generateServiceEndpoint('issue', path, query),
  faq: (path: string = '', query?: any) =>
    generateServiceEndpoint('faq', path, query),
  userGuide: (path: string = '', query?: any) =>
    generateServiceEndpoint('user-guide', path, query)
};
