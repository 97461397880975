import constants, {
  Colors,
  DATE_FORMAT_SHORT,
  FaultyReportStatus,
  JobTitle,
  JobTitleId,
  ParentSource,
  TemplateId
} from '@constants';
import {
  useAlertMutationEnhancer,
  useCloseFaultyReport,
  useCUDEquipment,
  useCUDEquipmentFaultyReport,
  useCUDEquipmentMaintenance,
  usePrint,
  useUncloseFaultyReport,
  useUser
} from '@hooks';
import { UseDataTableColumn } from '@hooks-dto';
import loadable from '@loadable/component';
import { UIUtils, TableUtils } from '@utils';
import { mappedFaultyReportStatus } from '@utils/mapped';
import { compact } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel } from 'uikit';

const TrailerComponentSelection = loadable(
  () => import('./FaultyReport/ComponentSelection')
);
const FaultyReportForm = loadable(() => import('./FaultyReport/Form'));
const MaintenanceForm = loadable(() => import('./Maintenance/Form'));

export const useDeleteEquipment = () => {
  const { deleteMutation } = useCUDEquipment();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const useDeleteFaultyReport = (id?: number | string) => {
  const { deleteMutation } = useCUDEquipmentFaultyReport(id);

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const useDeleteMaintenance = (id?: number | string) => {
  const { deleteMutation } = useCUDEquipmentMaintenance(id);

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const onShowEquipment = (id?: string | number | 'new') => {
  window.open(`/admin/equipment/${id}`, '_blank');
};

export const onShowFaultyReport = (equipmentId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('faulty_report'),
    maxWidth: 'lg',
    content: () => <FaultyReportForm item={v} equipmentId={equipmentId} />
  });
};

export const onShowTrailerComponentSelection = (equipment: any) => {
  UIUtils.popup.open({
    title: trans('select_sub_component'),
    maxWidth: 'sm',
    content: () => <TrailerComponentSelection equipment={equipment} />
  });
};

export const onShowMaintenance = (equipmentId: number, v?: any) => {
  UIUtils.popup.open({
    title: trans('maintenance'),
    maxWidth: 'lg',
    content: () => <MaintenanceForm item={v} equipmentId={equipmentId} />
  });
};

export const useDownloadFaultyReport = () => {
  const { mutate, isLoading } = usePrint();

  const onDownload = useCallback(
    (id?: number) => {
      const mParams = {
        templateId: TemplateId.VehicleFaultyReportNew,
        source: ParentSource.Allocation,
        childSource: constants.SOURCE_TYPE.VEHICLE_FAULTY,
        childSourceIds: [id]
      };
      mutate(mParams);
    },
    [mutate]
  );
  return { onDownload, isDownloading: isLoading };
};

export const useDownloadMaintenanceReport = () => {
  const { mutate, isLoading } = usePrint();

  const onDownload = useCallback(
    (id?: number) => {
      const mParams = {
        templateId: TemplateId.SuspensionService,
        source: ParentSource.Allocation,
        childSource: constants.SOURCE_TYPE.MAINTENANCE,
        childSourceIds: [id]
      };
      mutate(mParams);
    },
    [mutate]
  );
  return { onDownload, isDownloading: isLoading };
};

export const useFaultyReportColumnsFunc = (isGlobal: boolean = true) => {
  const { account } = useUser();
  const { onDownload, isDownloading } = useDownloadFaultyReport();

  const { onAlert, deleteLoading } = useDeleteFaultyReport();

  const { mutate: onCloseFaultyReport } = useCloseFaultyReport();
  const { mutate: onUncloseFaultyReport } = useUncloseFaultyReport();

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item =>
          compact([
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => onShowFaultyReport(item.equipmentId, item)
            },
            {
              title: trans('download'),
              icon: 'SaveAlt',
              onPress: () => onDownload(item?.id)
            },
            [FaultyReportStatus.Done].includes(item.progressStatus)
              ? {
                  title: trans('closed'),
                  icon: 'VisibilityOff',
                  onPress: () => {
                    onCloseFaultyReport({
                      equipmentId: item?.equipmentId,
                      id: item?.id,
                      staffId: account?.staff?.id
                    });
                  }
                }
              : undefined,
            [FaultyReportStatus.Closed].includes(item.progressStatus)
              ? {
                  title: trans('unclose'),
                  icon: 'Visibility',
                  onPress: () => {
                    onUncloseFaultyReport({
                      equipmentId: item?.equipmentId,
                      id: item?.id
                    });
                  }
                }
              : undefined,
            ![FaultyReportStatus.Cancelled, FaultyReportStatus.Done].includes(
              item.progressStatus
            )
              ? {
                  title: trans('delete'),
                  icon: 'Delete',
                  onPress: () =>
                    onAlert({ id: item.id, equipmentId: item.equipmentId })
                }
              : undefined
          ])
        )
      },
      {
        label: trans('faulty_date'),
        name: 'faultyDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('is_defect_notice_report'),
        name: 'isDefectNotice',
        options: {
          customBodyRender: (v?: any) => {
            let style = v
              ? {
                  color: KColors.primary.normal,
                  brC: KColors.primary.normal,
                  background: KColors.palette.primary.w50
                }
              : {
                  color: KColors.secondary.normal,
                  brC: KColors.secondary.normal,
                  background: KColors.palette.secondary.w50
                };
            return <KChip {...style} label={v ? 'Yes' : 'No'} />;
          }
        }
      },
      {
        label: trans('reason'),
        name: 'reason',
        options: TableUtils.options.chip({ uppercase: true, width: 80 })
      },
      {
        label: trans('code'),
        name: 'code',
        options: {
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text
                onPress={() => onShowFaultyReport(item.equipmentId, item)}
              >
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('name'),
        name: 'name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('progress_status'),
        name: 'progressStatus',
        options: TableUtils.options.chips({
          width: 100,
          uppercase: true,
          mapLabelData: mappedFaultyReportStatus()
        })
      },
      {
        label: trans('closed_at'),
        name: 'closedDateTime',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('closed_by'),
        name: 'closedOffBy.displayName',
        options: { ...TableUtils.options.withNA, ...TableUtils.options.baseXLg }
      },
      {
        label: trans('clearance_notice_file'),
        name: 'attachedClearanceNoticeEdoc',
        options: {
          customBodyRender: (v?: any) => {
            if (v?.resource) {
              return (
                <KLabel.Text
                  marginR="0.5rem"
                  key={`invoice-pdf-${v.id}`}
                  onPress={() => {
                    if (v.resource.url) {
                      window.open(v.resource.url, '_blank');
                    }
                  }}
                >
                  {v.resource.fileName}
                </KLabel.Text>
              );
            }

            return null;
          }
        }
      },
      {
        label: trans('clearance_notice_date'),
        name: 'clearanceNoticeDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('clearance_remark'),
        name: 'clearanceRemark'
      },
      {
        label: trans('equipment_code'),
        name: 'equipment.code',
        options: {
          ...TableUtils.options.toggle(isGlobal),
          customBodyRender: (v: any, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowEquipment(item.equipmentId)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('equipment_name'),
        name: 'equipment.name',
        options: {
          ...TableUtils.options.toggle(isGlobal),
          customBodyRender: (v: any, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowEquipment(item.equipmentId)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('registration_number'),
        name: 'equipment.registrationNumber'
      },
      {
        label: trans('equipment_type'),
        name: 'equipment.equipmentType',
        options: TableUtils.options.chip({ uppercase: true, width: 90 })
      },
      {
        label: trans('sub_type'),
        name: 'equipment.subType',
        options: TableUtils.options.chip({
          uppercase: true,
          css: { minWidth: 180 },
          props: { minW: 100 }
        })
      },
      {
        label: trans('current_kilometres'),
        name: 'currentKilometres',
        options: TableUtils.options.withNA
      },
      {
        label: trans('kms_last_faulty'),
        name: 'kmsLastFaulty',
        options: TableUtils.options.withNA
      },
      {
        label: trans('last_faulty_date'),
        name: 'lastFaultyDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('required_fault_repair'),
        name: 'requiredFaultRepair',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('actions_to_be_taken'),
        name: 'actionToBeTaken',
        options: {
          customBodyRenderLite: (idx: number) => {
            const item = data?.[idx];
            const actions = item?.actions ?? [];
            return (
              <KContainer.View row alignItems flexW="wrap">
                {actions.map((act: any) => {
                  return (
                    <KChip
                      key={`${item.id}-action-${act.actionId}`}
                      background={'#EDF9FF'}
                      color={KColors.blue.normal}
                      brC={KColors.blue.normal}
                      margin={'0.25rem'}
                      marginL={0}
                      label={act.action?.name ?? ''}
                    />
                  );
                })}
              </KContainer.View>
            );
          }
        }
      },
      {
        label: trans('level_of_fault'),
        name: 'levelOfFault',
        options: TableUtils.options.chip({ uppercase: true, width: 80 })
      },
      {
        label: trans('remark'),
        name: 'remark'
      },
      {
        label: trans('attached_files'),
        name: 'attachedFiles',
        options: {
          customBodyRenderLite: (idx: number) => {
            const item = data?.[idx];
            const files = item?.attachedFiles ?? [];
            return (
              <>
                {files.map((i: any) => {
                  return (
                    <KLabel.Text
                      marginR="0.5rem"
                      key={`${item.id}-file-${i.edocId}`}
                      onPress={() => {
                        if (i.edoc?.resource?.url) {
                          window.open(i.edoc?.resource.url, '_blank');
                        }
                      }}
                    >
                      {i.edoc?.resource?.fileName}
                    </KLabel.Text>
                  );
                })}
              </>
            );
          }
        }
      },
      {
        label: trans('reporter'),
        name: 'reportDriver.displayName',
        options: { ...TableUtils.options.withNA, ...TableUtils.options.baseXLg }
      },
      {
        label: trans('signed_date'),
        name: 'signedDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('reviewer'),
        name: 'reviewBy.displayName',
        options: { ...TableUtils.options.withNA, ...TableUtils.options.baseXLg }
      },
      {
        label: trans('review_date'),
        name: 'reviewDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('repair_date'),
        name: 'repairDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('repairer'),
        name: 'repairBy.displayName',
        options: { ...TableUtils.options.withNA, ...TableUtils.options.baseXLg }
      },
      {
        label: trans('workshop'),
        name: 'workshop.name',
        options: { ...TableUtils.options.withNA, ...TableUtils.options.baseXLg }
      },
      {
        label: trans('expected_good_condition_date'),
        name: 'expectedGoodConditionDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('invoice_pdf'),
        name: 'invoiceEdoc',
        options: {
          customBodyRender: (v?: any) => {
            if (v?.resource) {
              return (
                <KLabel.Text
                  marginR="0.5rem"
                  key={`invoice-pdf-${v.id}`}
                  onPress={() => {
                    if (v.resource.url) {
                      window.open(v.resource.url, '_blank');
                    }
                  }}
                >
                  {v.resource.fileName}
                </KLabel.Text>
              );
            }

            return null;
          }
        }
      },
      {
        label: trans('invoice_number'),
        name: 'invoiceNumber',
        options: TableUtils.options.withNA
      },
      {
        label: trans('approver'),
        name: 'approveBy.displayName',
        options: { ...TableUtils.options.withNA, ...TableUtils.options.baseXLg }
      },
      {
        label: trans('sign_off_date'),
        name: 'signoffDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('created_by'),
        name: 'createdUsername'
      },
      {
        label: trans('created_at'),
        name: 'createdAt'
      },
      {
        label: trans('updated_by'),
        name: 'updatedUsername'
      },
      {
        label: trans('updated_at'),
        name: 'updatedAt'
      }
    ],
    [
      account?.staff?.id,
      isGlobal,
      onAlert,
      onDownload,
      onCloseFaultyReport,
      onUncloseFaultyReport
    ]
  ) as (data: any) => UseDataTableColumn[];

  const mappedFields = useMemo(() => {
    return {
      'equipment.code': 'equipmentCode',
      'equipment.name': 'equipmentName',
      'closedOffBy.displayName': 'closedOffBy',
      'equipment.registrationNumber': 'registrationNumber',
      'equipment.equipmentType': 'equipmentType',
      'equipment.subType': 'subType',
      'reportDriver.displayName': 'reportDriver',
      'reviewBy.displayName': 'reviewer',
      'repairBy.displayName': 'repairer',
      'approveBy.displayName': 'approver',
      'workshop.name': 'workshop'
    };
  }, []);

  return {
    columnsFunc,
    mappedFields,
    extraLoading: deleteLoading || isDownloading
  };
};

export const useMaintenanceColumnsFunc = (isGlobal: boolean = true) => {
  const { onDownload } = useDownloadMaintenanceReport();

  const { onAlert, deleteLoading } = useDeleteMaintenance();

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item =>
          compact([
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => onShowMaintenance(item.equipmentId, item)
            },
            {
              title: trans('download'),
              icon: 'SaveAlt',
              onPress: () => onDownload(item?.id)
            },
            ![FaultyReportStatus.Cancelled, FaultyReportStatus.Done].includes(
              item.progressStatus
            )
              ? {
                  title: trans('delete'),
                  icon: 'Delete',
                  onPress: () =>
                    onAlert({ id: item.id, equipmentId: item.equipmentId })
                }
              : undefined
          ])
        )
      },
      {
        label: trans('maintenance_date'),
        name: 'maintenanceDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('is_suspension_service'),
        name: 'isSuspensionService',
        options: {
          customBodyRender: (v?: any) => {
            let style = v
              ? {
                  color: KColors.primary.normal,
                  brC: KColors.primary.normal,
                  background: KColors.palette.primary.w50
                }
              : {
                  color: KColors.secondary.normal,
                  brC: KColors.secondary.normal,
                  background: KColors.palette.secondary.w50
                };
            return <KChip {...style} label={v ? 'Yes' : 'No'} />;
          }
        }
      },
      {
        label: trans('service_type'),
        name: 'serviceType',
        options: {
          customBodyRender: (v?: any) => {
            return <KLabel.Text>{trans(v?.toLowerCase()) ?? ''}</KLabel.Text>;
          }
        }
      },
      {
        label: trans('frequency'),
        name: 'frequency'
      },
      {
        label: trans('code'),
        name: 'code'
      },
      {
        label: trans('name'),
        name: 'name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('progress_status'),
        name: 'progressStatus',
        options: TableUtils.options.chips({
          css: { minWidth: 140 },
          uppercase: true,
          mapLabelData: mappedFaultyReportStatus()
        })
      },
      {
        label: trans('equipment_code'),
        name: 'equipment.code',
        options: {
          ...TableUtils.options.toggle(isGlobal),
          customBodyRender: (v: any, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowEquipment(item.equipmentId)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('equipment_name'),
        name: 'equipment.name',
        options: {
          ...TableUtils.options.toggle(isGlobal),
          customBodyRender: (v: any, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowEquipment(item.equipmentId)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('registration_number'),
        name: 'equipment.registrationNumber'
      },
      {
        label: trans('equipment_type'),
        name: 'equipment.equipmentType',
        options: TableUtils.options.chip({ uppercase: true })
      },
      {
        label: trans('sub_type'),
        name: 'equipment.subType',
        options: TableUtils.options.chip({ uppercase: true })
      },
      {
        label: trans('end_date'),
        name: 'endDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('next_maintenance_date'),
        name: 'nextMaintenanceDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('current_kilometres'),
        name: 'currentKilometres',
        options: TableUtils.options.withNA
      },
      {
        label: trans('kms_last_faulty'),
        name: 'kmsLastFaulty',
        options: TableUtils.options.withNA
      },
      {
        label: trans('last_maintenance_date'),
        name: 'lastMaintenanceDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('required_maintenance'),
        name: 'requiredMaintenance',
        options: TableUtils.options.withEllipsis
      },
      // {
      //   label: trans('actions_to_be_taken'),
      //   name: 'actionToBeTaken',
      //   options: {
      //     customBodyRenderLite: (idx: number) => {
      //       const item = data?.[idx];
      //       const actions = item?.actions ?? [];
      //       return (
      //         <KContainer.View row alignItems flexW="wrap">
      //           {actions.map((act: any) => {
      //             return (
      //               <KChip
      //                 key={`${item.id}-action-${act.actionId}`}
      //                 background={'#EDF9FF'}
      //                 color={KColors.blue.normal}
      //                 brC={KColors.blue.normal}
      //                 margin={'0.25rem'}
      //                 marginL={0}
      //                 label={act.action?.name ?? ''}
      //               />
      //             );
      //           })}
      //         </KContainer.View>
      //       );
      //     }
      //   }
      // },
      {
        label: trans('remark'),
        name: 'remark'
      },
      {
        label: trans('attached_files'),
        name: 'attachedFiles',
        options: {
          customBodyRenderLite: (idx: number) => {
            const item = data?.[idx];
            const files = item?.attachedFiles ?? [];
            return (
              <>
                {files.map((i: any) => {
                  return (
                    <KLabel.Text
                      marginR="0.5rem"
                      key={`${item.id}-file-${i.edocId}`}
                      onPress={() => {
                        if (i.edoc?.resource?.url) {
                          window.open(i.edoc?.resource.url, '_blank');
                        }
                      }}
                    >
                      {i.edoc?.resource?.fileName}
                    </KLabel.Text>
                  );
                })}
              </>
            );
          }
        }
      },
      {
        label: trans('in_charge_driver'),
        name: 'inChargeDriver.displayName',
        options: TableUtils.options.withNA
      },
      {
        label: trans('signed_date'),
        name: 'signedDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('reviewer'),
        name: 'reviewBy.displayName',
        options: TableUtils.options.withNA
      },
      {
        label: trans('review_date'),
        name: 'reviewDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('maintainer'),
        name: 'maintenanceBy.displayName',
        options: TableUtils.options.withNA
      },
      {
        label: trans('workshop'),
        name: 'workshop.name',
        options: TableUtils.options.withNA
      },
      {
        label: trans('service_person_name'),
        name: 'servicePersonName',
        options: TableUtils.options.withNA
      },
      {
        label: trans('invoice_pdf'),
        name: 'invoiceEdoc',
        options: {
          customBodyRender: (v?: any) => {
            if (v?.resource) {
              return (
                <KLabel.Text
                  marginR="0.5rem"
                  key={`invoice-pdf-${v.id}`}
                  onPress={() => {
                    if (v.resource.url) {
                      window.open(v.resource.url, '_blank');
                    }
                  }}
                >
                  {v.resource.fileName}
                </KLabel.Text>
              );
            }

            return null;
          }
        }
      },
      {
        label: trans('invoice_number'),
        name: 'invoiceNumber',
        options: TableUtils.options.withNA
      },
      {
        label: trans('approver'),
        name: 'approveBy.displayName',
        options: TableUtils.options.withNA
      },
      {
        label: trans('sign_off_date'),
        name: 'signoffDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('created_by'),
        name: 'createdUsername'
      },
      {
        label: trans('created_at'),
        name: 'createdAt'
      },
      {
        label: trans('updated_by'),
        name: 'updatedUsername'
      },
      {
        label: trans('updated_at'),
        name: 'updatedAt'
      }
    ],
    [isGlobal, onAlert, onDownload]
  ) as (data: any) => UseDataTableColumn[];

  const mappedFields = useMemo(() => {
    return {
      'equipment.registrationNumber': 'registrationNumber',
      'equipment.equipmentType': 'equipmentType',
      'equipment.subType': 'subType',
      'inChargeDriver.displayName': 'inChargeDriver',
      'reviewBy.displayName': 'reviewer',
      'maintenanceBy.displayName': 'maintainer',
      'approveBy.displayName': 'approver',
      'workshop.name': 'workshop'
    };
  }, []);

  return { columnsFunc, mappedFields, deleteLoading };
};

export const useFaultyJobTitle = (id?: number) => {
  if (!id) {
    return undefined;
  }

  const reporters = [
    JobTitle.InHouseWorkshopManager,
    JobTitle.OperationManager
  ];
  const isReporter = [
    JobTitleId.InHouseWorkshopManager,
    JobTitleId.OperationManager
  ].includes(id);

  const reviewers = [
    JobTitle.InHouseWorkshopManager,
    JobTitle.FleetController,
    JobTitle.OperationManager
  ];
  const isReviewer = [
    JobTitleId.InHouseWorkshopManager,
    JobTitleId.FleetController,
    JobTitleId.OperationManager
  ].includes(id);

  const repairers = [JobTitle.Mechanic, JobTitle.OperationManager];
  const isRepairer = [
    JobTitleId.Mechanic,
    JobTitleId.OperationManager
  ].includes(id);

  const approvers = [JobTitle.OperationManager];
  const isApprover = [JobTitleId.OperationManager].includes(id);

  return {
    isReporter,
    isReviewer,
    isRepairer,
    isApprover,

    reporters,
    reviewers,
    repairers,
    approvers
  };
};

export const showSignatureTooltipTitle = (
  // positionList: any[],
  signer: string
) => {
  return (
    <KLabel.Text color={Colors.white}>
      {/* {`${trans('position_requirement')}: ${positionList.map(i => trans(i.toLocaleLowerCase())).join(', ')}`}
      <br /> */}
      {`${trans('signed_by')}: ${signer ?? 'N/A'}`}
    </KLabel.Text>
  );
};
