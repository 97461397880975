import {
  DATE_FORMAT_SHORT,
  ENDPOINTS,
  RoutingType,
  ShipmentType,
  TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from '@containers/DataTable';
import { Prototype } from '@core';
import { useDataTable, useGlobalTable, useNewButton } from '@hooks';
import { TableUtils } from '@utils';
import { mappedContainerInvoiceStatus } from '@utils/mapped';
import React, { memo, useCallback, useMemo, useState } from 'react';
import trans from 'translation';
import { KBreadcrumbs, KContainer, KLabel } from 'uikit';

import { onShowTransportJob } from './helpers';

const renderRouting = (
  container: any,
  types: string | string[],
  isWarehouse = false
) => {
  if (typeof types === 'string') {
    types = [types];
  }

  if (container) {
    const routings = container?.jobContainerRoutings;
    if (routings) {
      const filteredItems = routings
        .filter((i: any) => types.includes(i.routingType))
        .map((i: any) =>
          isWarehouse ? i.relatedPartySite?.relatedSite?.name : i.client?.name
        )
        .filter((i: any) => i);
      return filteredItems.join(' - ');
    }
  }

  return '';
};

const renderEstTime = (container: any, types: string | string[]) => {
  if (typeof types === 'string') {
    types = [types];
  }

  if (container) {
    const routings = container?.jobContainerRoutings;
    if (routings) {
      const item = routings
        .filter((i: any) => types.includes(i.routingType))
        .map((i: any) => i.deliveryPickupTimeslotEstDatetime)?.[0];
      return Prototype.date.formatDT(item) ?? '';
    }
  }

  return '';
};

const TransportJob = () => {
  const [tab] = useState(ShipmentType.FCL);

  const columnsFunc = useCallback(
    (data: any[]) => [
      {
        label: trans('action'),
        name: 'action',
        options: TableUtils.options.actionWithMenuList(data, item => [
          {
            title: trans('edit'),
            icon: 'EditOutlined',
            onPress: () => onShowTransportJob(item.id)
          }
        ])
      },
      {
        label: trans('job_number'),
        name: 'jobNumber',
        options: {
          ...TableUtils.options.baseMd,
          customBodyRender: (v: string, rowData: any) => {
            const item = data?.[rowData.rowIndex];
            return (
              <KLabel.Text onPress={() => onShowTransportJob(item.id)}>
                {v}
              </KLabel.Text>
            );
          }
        }
      },
      {
        label: trans('job_status'),
        name: 'jobProgress',
        options: TableUtils.options.chip()
      },
      {
        label: trans('job_type'),
        name: 'jobType'
      },
      {
        label: trans('customer_reference'),
        name: 'referenceNumber'
      },
      {
        label: trans('vessel'),
        name: 'vessel.vessel',
        options: TableUtils.options.baseMd
      },
      {
        label: trans('lloyd'),
        name: 'vessel.lloyd'
      },
      {
        label: trans('voyage'),
        name: 'voyage'
      },
      {
        label: trans('unloco_board_of_loading'),
        name: 'unlocoBoardOfLoading'
      },
      {
        label: trans('unloco_board_of_discharge'),
        name: 'unlocoBoardOfDischarge'
      },
      {
        label: trans('eta'),
        name: 'eta',
        options: TableUtils.options.date()
      },
      {
        label: trans('etd'),
        name: 'etd',
        options: TableUtils.options.date()
      },
      {
        label: trans('imp_available_date'),
        name: 'avail',
        options: TableUtils.options.date()
      },
      {
        label: trans('imp_storage_start_date'),
        name: 'stor',
        options: TableUtils.options.date()
      },
      {
        label: trans('imp_storage_last_free_date'),
        name: 'storLastFreeDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('1st_free_date'),
        name: 'firstFreeDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('discharge_date'),
        name: 'firstDischargeDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('exp_receival_commencement_date'),
        name: 'expRecvDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('cargo_cutoff_date'),
        name: 'cutOffDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('reefer_cutoff_date'),
        name: 'reeferCutoffDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('empty_receival_date'),
        name: 'emptyReceivalCommencementDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('empty_cutoff_date'),
        name: 'emptyCutoffDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('hazardous_receival_date'),
        name: 'hazardousReceivalCommencementDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('hazardous_cutoff_date'),
        name: 'hazardousCutoffDate',
        options: TableUtils.options.date()
      },
      {
        label: trans('agent_code'),
        name: 'agentClient.code'
      },
      {
        label: trans('agent_name'),
        name: 'agentClient.name',
        options: TableUtils.options.base2XLg
      },
      {
        label: trans('consignee_consignor_code'),
        name: 'consignClient.code'
      },
      {
        label: trans('consignee_consignor_name'),
        name: 'consignClient.name',
        options: TableUtils.options.base2XLg
      },
      {
        label: trans('account_receivable_code'),
        name: 'relatedPartyArClient.relatedClient.code'
      },
      {
        label: trans('account_receivable_name'),
        name: 'relatedPartyArClient.relatedClient.name',
        options: TableUtils.options.base2XLg
      },
      {
        label: trans('warehouse_code'),
        name: 'warehouseClient.code'
      },
      {
        label: trans('warehouse_name'),
        name: 'warehouseClient.name',
        options: TableUtils.options.base2XLg
      },
      {
        label: trans('container_number'),
        name: 'jobContainer.containerNumber'
      },
      {
        label: trans('container_status'),
        name: 'jobContainer.containerProgress',
        options: TableUtils.options.chip()
      },
      {
        label: trans('invoice_status'),
        name: 'jobContainer.containerInvoiceStatus',
        options: TableUtils.options.chips({
          css: { minWidth: 140 },
          uppercase: true,
          mapLabelData: mappedContainerInvoiceStatus()
        })
      },
      {
        label: trans('drop_mode'),
        name: 'jobContainer.dropMode.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('container_size'),
        name: 'jobContainer.containerSize.code'
      },
      {
        label: trans('cto_rail_head'),
        name: 'cto',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(container, [
              RoutingType.Cto,
              RoutingType.RailHead
            ]);
          }
        }
      },
      {
        label: trans('cto_rail_head_est_time'),
        name: 'ctoEstTime',
        options: {
          ...TableUtils.options.baseXLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderEstTime(container, [
              RoutingType.Cto,
              RoutingType.RailHead
            ]);
          }
        }
      },
      {
        label: trans('wait_depot_full'),
        name: 'wdf',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(container, [
              RoutingType.WaitDepotFullDropOff,
              RoutingType.WaitDepotFullStopBy
            ]);
          }
        }
      },
      {
        label: trans('wait_depot_full_est_time'),
        name: 'wdfEstTime',
        options: {
          ...TableUtils.options.baseXLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderEstTime(container, [
              RoutingType.WaitDepotFullDropOff,
              RoutingType.WaitDepotFullStopBy
            ]);
          }
        }
      },
      {
        label: trans('delivery_warehouse'),
        name: 'dw',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(
              container,
              RoutingType.DeliveryWarehouse,
              true
            );
          }
        }
      },
      {
        label: trans('delivery_warehouse_est_time'),
        name: 'dwEstTime',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderEstTime(container, RoutingType.DeliveryWarehouse);
          }
        }
      },
      {
        label: trans('pickup_warehouse'),
        name: 'pw',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(container, RoutingType.PickupWarehouse, true);
          }
        }
      },
      {
        label: trans('pickup_warehouse_est_time'),
        name: 'pwEstTime',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderEstTime(container, RoutingType.PickupWarehouse);
          }
        }
      },
      {
        label: trans('wait_depot_empty'),
        name: 'wde',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(container, [
              RoutingType.WaitDepotEmptyDropOff,
              RoutingType.WaitDepotEmptyStopBy
            ]);
          }
        }
      },
      {
        label: trans('wait_depot_empty_est_time'),
        name: 'wdeEstTime',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderEstTime(container, [
              RoutingType.WaitDepotEmptyDropOff,
              RoutingType.WaitDepotEmptyStopBy
            ]);
          }
        }
      },
      {
        label: trans('empty_yard'),
        name: 'emptyYard',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(container, RoutingType.EmptyYard);
          }
        }
      },
      {
        label: trans('empty_yard_est_time'),
        name: 'emptyYardEstTime',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderEstTime(container, RoutingType.EmptyYard);
          }
        }
      },
      {
        label: trans('wait_depot_misc'),
        name: 'wdm',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(container, [
              RoutingType.WaitDepotMiscDropOff,
              RoutingType.WaitDepotMiscStopBy
            ]);
          }
        }
      },
      {
        label: trans('warehouse_misc'),
        name: 'wm',
        options: {
          ...TableUtils.options.base2XLg,
          sort: false,
          customBodyRenderLite: (index: number) => {
            const container = data?.[index]?.jobContainer;
            return renderRouting(container, RoutingType.Warehouse, true);
          }
        }
      }
    ],
    []
  );

  const excludedExportColumns = useMemo(
    () => [
      trans('cto_rail_head'),
      trans('cto_rail_head_est_time'),
      trans('wait_depot_full'),
      trans('wait_depot_full_est_time'),
      trans('delivery_warehouse'),
      trans('delivery_warehouse_est_time'),
      trans('pickup_warehouse'),
      trans('pickup_warehouse_est_time'),
      trans('warehouse_misc'),
      trans('wait_depot_misc'),
      trans('empty_yard'),
      trans('empty_yard_est_time'),
      trans('wait_depot_empty'),
      trans('wait_depot_empty_est_time')
    ],
    []
  );

  const mappedFields = useMemo(
    () => ({
      'agentClient.code': 'agentClientCode',
      'agentClient.name': 'agentClientName',
      'consignClient.code': 'consignClientCode',
      'consignClient.name': 'consignClientName',
      'relatedPartyArClient.relatedClient.code': 'accountsClientCode',
      'relatedPartyArClient.relatedClient.name': 'accountsClientName',
      'warehouseClient.code': 'warehouseClientCode',
      'warehouseClient.name': 'warehouseClientName',
      'jobContainer.containerNumber': 'containerNumber',
      'jobContainer.dropMode.name': 'dropMode',
      'jobContainer.containerSize.code': 'containerSize',
      'jobContainer.containerProgress': 'containerProgress',
      'jobContainer.containerInvoiceStatus': 'containerInvoiceStatus',
      'vessel.vessel': 'vessel',
      'vessel.lloyd': 'lloyd'
    }),
    []
  );

  const otherParams = useMemo(
    () => ({
      shipmentType: tab
    }),
    [tab]
  );

  const table = useDataTable({
    name: WebTable.TRANSPORT_JOB,
    tableName: TableName.TRANSPORT_JOB,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITHOUT_TABS
    },
    apiURL: ENDPOINTS.transportJob(),
    columnsFunc,
    mappedFields,
    otherParams
    // isInitFetch: false
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => {
    onShowTransportJob('new', { shipmentType: tab });
  }, [tab]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        title={trans('routes.transport_jobs')}
      />

      <KContainer.Card
        margin="0.75rem"
        // header={{
        //   renderHeader: () => (
        //     <Tabs.Shipment onChangeTab={setTab} disabled={table.isLoading} />
        //   )
        // }}
      >
        <DataTable
          {...table}
          excludedExportColumns={excludedExportColumns}
          onAdd={onAdd}
        />
      </KContainer.Card>

      {newButton}
    </>
  );
};

export default memo(TransportJob);
