import { PaymentType, SyncStatus } from '@constants';
import { AgUtils } from '@utils';
import { mappedInvoiceType, mappedSyncStatus } from '@utils/mapped';
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  IHeaderParams
} from 'ag-grid-community';
import { CustomCellEditorProps } from 'ag-grid-react';
import { compact } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import trans from 'translation';
import { KInput } from 'uikit';

export interface IFormDataInvoice {
  invoice: any;
  invoiceId: number;
  invoiceAmount: number;
  totalAmount: number;
  totalDue: number;
  totalPaid: number;
  checked: boolean;
}

export interface IFormData {
  paymentDate: any;
  totalAmount: 0;
  bankAccount?: any;
  referenceNumber: string;
  paymentType: PaymentType;
  accountNumber: string;
  accountName: string;
  bankBranch: string;
  bankCode: string;
  note: string;
  attachedFile?: any;
  invoices: IFormDataInvoice[];
}

const CustomCheckboxHeader = memo(({ api }: IHeaderParams) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const onCellValueChanged = (event: CellValueChangedEvent<any>) => {
      if (event.column.getColId()) {
        if (
          event.api
            .getRenderedNodes()
            .filter(
              node =>
                node.data.invoice.syncStatus ===
                SyncStatus.Success.toUpperCase()
            )
            .every(node => {
              return !!node.data.checked;
            }) !== isChecked
        )
          setIsChecked(
            event.api
              .getRenderedNodes()
              .filter(
                node =>
                  node.data.invoice.syncStatus ===
                  SyncStatus.Success.toUpperCase()
              )
              .every(node => {
                return !!node.data.checked;
              })
          );
      }
    };

    api.addEventListener('cellValueChanged', onCellValueChanged);

    return () => {
      api.removeEventListener('cellValueChanged', onCellValueChanged);
    };
  }, [api, isChecked]);

  return (
    <div
      className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${isChecked ? 'ag-checked' : ''}`}
    >
      <input
        type="checkbox"
        className="ag-input-field-input ag-checkbox-input"
        checked={isChecked}
        onChange={() => {
          api.getRenderedNodes().forEach(node => {
            if (
              node.data.invoice.syncStatus === SyncStatus.Success.toUpperCase()
            ) {
              node.setDataValue('checked', !isChecked);
            }
          });
        }}
      />
    </div>
  );
});

export const useColDefs = (isView = false) => {
  const colDefs: ColDef<any>[] | ColGroupDef<any>[] = useMemo(
    () =>
      compact([
        !isView
          ? {
              maxWidth: 50,
              pinned: true,
              filter: false,
              sortable: false,
              lockPinned: true,
              headerName: ' ',
              field: 'checked',
              editable: ({ node }) => {
                return (
                  node.data.invoice.syncStatus ===
                  SyncStatus.Success.toUpperCase()
                );
              },
              cellEditor: 'agCheckboxCellEditor',
              headerComponent: CustomCheckboxHeader
            }
          : undefined,
        {
          headerName: trans('invoice_number'),
          field: 'invoice.code',
          minWidth: 140,
          pinned: true
        },
        {
          headerName: trans('invoice_type'),
          field: 'invoice.invoiceType',
          ...AgUtils.options.chips({
            mapLabelData: mappedInvoiceType()
          })
        },
        {
          headerName: trans('invoice_status'),
          field: 'invoice.progressStatus',
          ...AgUtils.options.chip()
        },
        {
          headerName: trans('invoice_date'),
          field: 'invoice.invoiceDate',
          type: 'vnDate'
        },
        {
          headerName: trans('sync_status'),
          field: 'invoice.syncStatus',
          ...AgUtils.options.chips({
            mapLabelData: mappedSyncStatus()
          })
        },
        {
          headerName: trans('invoice_amount'),
          field: 'invoiceAmount',
          ...AgUtils.options.currency()
        },
        {
          headerName: trans('amount_due'),
          field: 'totalDue',
          ...AgUtils.options.currency()
        },
        {
          headerName: trans('payment_amount'),
          field: 'totalAmount',
          ...AgUtils.options.currency(),
          singleClickEdit: true,
          editable: ({ node }) => {
            return !isView && !!node.data.checked;
          },
          cellEditor: ({
            value,
            onValueChange
          }: CustomCellEditorProps<any>) => {
            return (
              <KInput.Currency
                value={value}
                onChange={e => {
                  onValueChange(parseFloat(e.target.value));
                }}
              />
            );
          }
        },
        isView
          ? {
              headerName: trans('payment_sync_status'),
              field: 'syncStatus',
              minWidth: 140,
              ...AgUtils.options.chips({
                mapLabelData: mappedSyncStatus()
              })
            }
          : undefined
      ]),
    [isView]
  );

  return colDefs;
};
